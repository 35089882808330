<template>
	<MobileAppWizardStep v-model="application" :steps="steps" :main="true" @done="$emit('done')" />
</template>

<script>
export default {
	name: 'MobileAppStepDelete',
	components: {
		MobileAppWizardStep: () => ({
			component: import('@/components/SuperAdmin/MobileApp/CreationSteps/MobileAppWizardStep')
		})
	},
	props: {
		value: {
			required: true,
			type: Object
		},
		stepName: {
			required: true,
			type: String
		}
	},
	data: function () {
		return {
			stepsValidated: false,
			steps: [
				{
					title: this.$t('mobileapp.actions.confirm_delete')
				},
				{
					title: this.$t('mobileapp.deletionSteps.1.title'),
					href: () => 'https://play.google.com/console/u/0/developers'
				},
				{
					title: this.$t('mobileapp.deletionSteps.1.steps.1.title')
				},
				{
					title: this.$t('mobileapp.deletionSteps.1.steps.2.title')
				},
				{
					title: this.$t('mobileapp.deletionSteps.1.steps.3.title')
				},
				{
					title: this.$t('mobileapp.deletionSteps.2.title'),
					href: () => 'https://appstoreconnect.apple.com/',
					valueToCopy: () => 'admin@welyb.fr'
				},
				{
					title: this.$t('mobileapp.deletionSteps.2.steps.1.title')
				},
				{
					title: this.$t('mobileapp.deletionSteps.2.steps.2.title')
				},
				{
					title: this.$t('mobileapp.deletionSteps.2.steps.3.title')
				},
				{
					title: this.$t('mobileapp.deletionSteps.2.steps.4.title')
				},
				{
					title: this.$t('mobileapp.deletionSteps.2.steps.5.title')
				}
			]
		}
	},
	computed: {
		application: {
			get: function () {
				return this.value
			},
			set: function (val) {
				this.$emit('input', val)
			}
		}
	}
}
</script>
